import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { usePagination } from "react-use-pagination";
import { toVWDesktop, toVWMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { BackButton } from "../shared/BackButton";
import { ROUTES } from "../../helpers/routes";
import Header from "./shared/Header";
import SearchTags from "../SearchTags";
import { Tag } from "../../types";
import { Paginator } from "../shared/Paginator";
import { searchInObject } from "../../helpers/search";
import BlogCard, { BlogCardProps } from "../BlogCard";
import { getQueryParams } from "../../helpers/location";

export type PressReleaseSearchProps = {
  title: string;
  backLabel: string;
  searchLabel: string;
  articles: Array<BlogCardProps>;
  tags: Array<Tag>;
};

const ArticlesSearch: FC<PressReleaseSearchProps> = ({
  title,
  backLabel,
  searchLabel,
  articles = [],
  tags,
}: PressReleaseSearchProps) => {
  const [activeTag, setActiveTag] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredCardList = useMemo(() => {
    return articles.filter(
      (pRelease) =>
        (searchTerm ? searchInObject(pRelease, searchTerm) : true) &&
        pRelease?.tags?.find(
          (tag) =>
            tag.value === tags[activeTag]?.value || !tags[activeTag]?.value
        )
    );
  }, [articles, searchTerm, activeTag]);

  const {
    currentPage,
    totalPages,
    setPage,
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
    startIndex,
    endIndex,
  } = usePagination({
    totalItems: filteredCardList?.length ?? 0,
    initialPageSize: 9,
  });

  useEffect(() => {
    const queryParams = getQueryParams();
    const currentTagIndex = tags?.findIndex(
      (tag) => tag?.value?.toLowerCase() === queryParams?.tag?.toLowerCase()
    );
    currentTagIndex >= 0 && setActiveTag(currentTagIndex);
    setSearchTerm(queryParams?.query);
  }, []);

  const handleActiveTagChange = useCallback(
    (activeTagIndex: number) => {
      setActiveTag(activeTagIndex);
    },
    [setActiveTag]
  );

  const handlePageChange = useCallback(
    (active: number) => {
      setPage(active);
    },
    [setPage]
  );

  const handleNextPage = useCallback(() => {
    setNextPage();
  }, [setNextPage]);

  const handlePrevPage = useCallback(() => {
    setPreviousPage();
  }, [setPreviousPage]);

  useEffect(() => {
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);

  return (
    <Container>
      <StyledBackButton label={backLabel} link={`${ROUTES.NEWSROOM}#blog`} />
      <Header title={title} />

      <StyledSearchTags
        tags={tags}
        searchPlaceholder={searchLabel}
        setActive={handleActiveTagChange}
        active={activeTag}
        searchTerm={searchTerm}
        onSearchTermChange={(v) => setSearchTerm(v)}
      />
      <CardsContainer id={"cards-container"}>
        {filteredCardList
          .slice(startIndex, endIndex + 1)
          .map((rCard, index) => (
            <BlogCard key={index} {...rCard} />
          ))}
      </CardsContainer>

      <Paginator
        totalPages={totalPages}
        currentPage={currentPage + 1}
        onClick={handlePageChange}
        onNext={handleNextPage}
        onNextDisabled={!nextEnabled}
        onPrev={handlePrevPage}
        onPrevDisabled={!previousEnabled}
      />
    </Container>
  );
};

const Container = styled.div`
  background-color: #033305;
  display: flex;
  flex-direction: column;
  padding: ${toVWMobile(16)}vw ${toVWMobile(19)}vw ${toVWMobile(80)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(42)}vw ${toVWDesktop(100)}vw ${toVWDesktop(80)}vw;
  }
`;

const StyledSearchTags = styled(SearchTags)`
  margin-top: ${toVWMobile(0)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(25)}vw;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: ${toVWMobile(40)}vw;
  margin-top: ${toVWMobile(40)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(40)}vw;
    gap: initial;
    display: grid; /* 1 */
    grid-template-columns: repeat(auto-fill, ${toVWDesktop(400)}vw); /* 2 */
    grid-gap: ${toVWDesktop(80)}vw ${toVWDesktop(10)}vw;
    justify-content: space-between; /* 4 */
    flex-direction: initial;
  }
`;

const StyledBackButton = styled(BackButton)`
  direction: ltr;
`;

export default ArticlesSearch;
