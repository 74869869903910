import { graphql } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import React, { useCallback } from "react";
import { formatDate } from "../helpers/date";

import Page from "../components/Page";
import ArticlesSearch from "../components/ArticlesSearch/ArticlesSearch";
import { getTranslations } from "../helpers/translations";
import { get } from "lodash";
import { BlogCardProps } from "../components/BlogCard";
import { getAssetURL } from "../helpers/assets";
import { Tag, TagType } from "../types.d.ts";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { isArticleValid } from "../helpers/article";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { isPublished } from "../helpers/directus";

const Articles = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return data?.directus?.article?.map((article: any) => {
      const translatedArticle =
        getTranslations(article, "translations", intl.locale) ?? {};
      return {
        ...translatedArticle,
        article_slug: article?.article_slug,
        article_type: article?.article_type,
      };
    });
  }, [data]);

  const getArticles = useCallback((): BlogCardProps[] => {
    const articles = getRootTranslatedData();

    return articles
      .filter((article) => article?.article_type?.type_value === TagType?.BLOG)
      .map((article) => {
        const translatedArticleType = getTranslations(
          article,
          "article_type.translations",
          intl.locale
        );
        const tags = get(article, "tags", []);
        const parsedTags: Tag[] = tags?.map((tag) => {
          const translatedTag = getTranslations(
            tag,
            "article_tag_id.translations",
            intl.locale
          );
          return {
            label: translatedTag?.tag_name,
            value: translatedTag?.tag_name,
          };
        });
        return {
          status: article?.status,
          infoStatus: article?.info_status,
          title: article?.article_title,
          description: article?.article_description,
          image: {
            src: getAssetURL(article?.article_image),
            alt: getAssetURL(article?.article_image_alt),
          },
          thumbnail: {
            src: getAssetURL(article?.article_thumbnail),
            alt: getAssetURL(article?.article_thumbnail_alt),
          },
          slug: article?.article_slug,
          date: formatDate(article?.article_date),
          readMore: labels?.readMoreLabel,
          type: translatedArticleType?.article_type_label,
          tags: parsedTags,
        };
      })
      ?.filter(
        (a) =>
          isArticleValid(a) &&
          isPublished(a?.status) &&
          isPublished(a?.infoStatus)
      )
      ?.sort((a, b) => {
        if (a.date)
          return new Date(b?.date).getTime() - new Date(a?.date).getTime();
      });
  }, [getRootTranslatedData, intl]);

  const getTags = useCallback((): Tag[] => {
    const tags = data?.directus?.article_tag;
    const allTag: Tag = {
      label: labels?.blogAllTag,
      value: "",
    };

    return [
      allTag,
      ...tags
        .filter((tag) => tag?.tag_type?.type_value === TagType.BLOG)
        .map((tag) => {
          const translatedTag = getTranslations(
            tag,
            "translations",
            intl.locale
          );
          return {
            label: translatedTag?.tag_name,
            value: translatedTag?.tag_name,
          };
        }),
    ];
  }, [data, intl]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
    >
      <ArticlesSearch
        title={labels?.allBlogsTitle}
        backLabel={labels?.allBlogsBackToBlog}
        searchLabel={labels?.allBlogsSearchPlaceholder}
        tags={getTags()}
        articles={getArticles()}
      />
    </Page>
  );
};

export default Articles;

export const query = graphql`
  query ($locale: String) {
    directus {
      article(limit: -1) {
        article_slug
        article_type {
          type_value
          translations(filter: { languages_code: { code: { _eq: $locale } } }) {
            languages_code {
              name
              code
            }
            article_type_label
          }
        }
        id
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            name
            code
          }
          status
          tags {
            article_tag_id {
              tag_type {
                type_value
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    name
                    code
                  }
                  article_type_label
                }
              }
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  name
                  code
                }
                tag_name
              }
            }
          }
          info_status
          article_title
          article_description
          article_date
          article_image_alt
          article_image {
            id
            filename_disk
          }
          article_thumbnail_alt
          article_thumbnail {
            id
            filename_disk
          }
          article_file {
            id
            filename_disk
          }
          article_content
          article_questions_title
          article_questions_accordion
        }
      }
      article_tag {
        tag_type {
          type_value
          status
          id
          translations(filter: { languages_code: { code: { _eq: $locale } } }) {
            id
            languages_code {
              code
              name
            }
            article_type_label
          }
        }
        id
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          id
          languages_code {
            code
            name
          }
          tag_name
        }
      }
    }
  }
`;
